import "./App.css";
import { Link } from "react-router-dom";
import supergraphic from "./assets/img/bosch_supergraphic_strip.png";
import logo from "./assets/img/bosch_logo_slogan.svg";

function App() {
  const lineBreakStyle = {
    margin: "5px 0",
  };

  return (
    <div>
      <img
        src={supergraphic}
        alt=""
        style={{ width: "100%", height: "15px" }}
      />
      <div className="App" style={{ width: "80%", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="" style={{ width: "20%" }} />
          <h3>{""}</h3>
        </div>
        <div
          style={{
            borderBottom: "2px solid #ddd", // Adjust the border style as needed
            margin: "10px 0", // Adjust the margin to control spacing
          }}
        ></div>
        <section className="mb-4">
          <h3 style={{ textDecoration: "underline" }}>IMPRINT</h3>
          <p>
            <b>East Africa</b>
            <br />
            <br />

            <p>Name and address </p>

            <p style={lineBreakStyle}>Robert Bosch East Africa Ltd.</p>
            <p style={lineBreakStyle}>Delta Chambers, Waiyaki Way,</p>
            <p style={lineBreakStyle}>856-00606 Westlands, Nairobi</p>
            <p style={lineBreakStyle}>Kenya</p>
            <br />
            <p style={lineBreakStyle}>General Manager</p>
            <p style={lineBreakStyle}>Mr. Ghislain Noumbessy</p>
            <br />
            <p style={lineBreakStyle}>
              Your contact at Robert Bosch East Africa Ltd.{" "}
            </p>
            <p style={lineBreakStyle}>Email: appcrunch254@bosch.com</p>
            <p style={lineBreakStyle}>Phone: +254 711 11 55 66</p>
          </p>
          <br />
        </section>
        <div
          style={{
            borderBottom: "2px solid #ddd", // Adjust the border style as needed
            margin: "10px 0", // Adjust the margin to control spacing
          }}
        ></div>
        <section className="mb-4">
          <h3 className="h4" style={{ textDecoration: "underline" }}>
            LEGAL NOTICE (STANDARD)
          </h3>
          <p>
            <b>© Copyright </b>
          </p>
          <p>
            All rights reserved. Text, images, graphics, sound, animations, and
            videos as well as the arrangement of the same on Bosch websites are
            protected by copyright and other commercial protective rights. The
            content of these websites may not be copied, disseminated, altered,
            or made accessible to third parties for commercial purposes. In
            addition, some Bosch websites contain images that are subject to
            third-party copyrights.
          </p>
          <p>
            <b>Trademarks </b>
          </p>
          <p>
            Unless specified otherwise, all trademarks on Bosch websites are
            protected by trademark law. This applies in particular to Bosch
            brands, nameplates, company logos, and emblems. The brands and
            design elements used on our pages are the intellectual property of
            Robert Bosch GmbH, Germany. Sony, EXMOR and EXMOR R are trademarks
            of Sony Corporation.
          </p>
          <p>
            <b>Disclaimer </b>
          </p>
          <p>
            These websites were compiled with the utmost care. Nonetheless, the
            accuracy or correctness of the information they contain cannot be
            guaranteed. Bosch shall not be liable for any loss or damage
            resulting directly or indirectly from use of these websites, unless
            caused intentionally or in gross negligence by Bosch.
          </p>
          <p>
            <b>Licensing </b>
          </p>
          <p>
            The intellectual property such as patents, marks, and copyrights
            contained on Bosch websites is protected. These websites do not
            grant a license for utilizing the intellectual property of companies
            of the Bosch Group (Bosch) or third parties.
          </p>
          <p>
            <b>Order of precedence </b>
          </p>
          <p>
            Terms of use for a digital offering take precedence over these legal
            notes.
          </p>
          <br />
        </section>

        <section className="mb-4">
          <h2 className="h4" style={{ textDecoration: "underline" }}>
            Data Protection Notice
          </h2>

          <p>
            Robert Bosch East Africa Ltd. / Robert Bosch Nigeria Ltd.
            (hereinafter "Bosch“ or "We“ or "Us") is delighted about your visit
            to our internet pages and mobile applications (together also
            referred to as "Online Offers") and about your interest in our
            company and our products.
          </p>
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <h1>1. Bosch respects your privacy</h1>
          <p>
            The protection of your privacy throughout the course of processing
            personal data as well as the security of all business data is an
            important concern to us. We process personal data that was gathered
            during your visit of our Online Offers confidentially and only in
            accordance with statutory regulations.
            <br />
            <br />
            Data protection and information security are included in our
            corporate policy.
          </p>
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <h1>2. Controller</h1>
          <p>
            Bosch is the controller responsible for the processing of your data;
            exceptions are outlined in this data protection notice.
            <br />
            <br /> Our contact details are as follows:
            <p>
              <b>East Africa</b>
              <br />

              <b>Name and address </b>
              <br />
              <p style={lineBreakStyle}>Robert Bosch East Africa Ltd.</p>
              <p style={lineBreakStyle}>
                Delta Chambers, 7th Floor, Waiyaki Way,
              </p>
              <p style={lineBreakStyle}>856-00606 Westlands, Nairobi</p>
              <p style={lineBreakStyle}>Kenya</p>
              <br />

              <p style={lineBreakStyle}>
                Your contact at Robert Bosch East Africa Ltd.
              </p>
              <p style={lineBreakStyle}>Email: appcrunch254@bosch.com</p>
              <p style={lineBreakStyle}>Phone: +254 711 11 55 66</p>
            </p>
            <br />
          </p>
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <h1>3. Collection, processing and usage of personal data</h1>
          <p>
            {" "}
            <b>3.1. Processed categories of data</b>
          </p>
          <p>
            The following categories of data are collected and processed:
            Communication data (e.g. name, telephone, e-mail, address, IP
            address) Contractual master data (e.g. contractual relationships,
            contractual or product interest) Client history Location
          </p>
          <br />
          <p>
            <b>3.2. Principles</b>
          </p>
          <p>
            Personal data consists of all information related to an identified
            or identifiable natural person; this includes, e.g. names,
            addresses, phone numbers, email addresses, contractual master data,
            contract accounting and payment data, which is an expression of a
            person's identity.
            <br />
            <br /> We collect, process and use personal data (including IP
            addresses) only when there is either a statutory legal basis to do
            so, or you have given your consent to the processing or use of
            personal data concerning this matter, e.g. by means of registration.
          </p>
          <br />
          <p>
            <b>3.3. Processing purposes and legal bases</b>
          </p>
          <p>
            We and service providers commissioned by us process your personal
            data for the following processing purposes: Provision of these
            Online Offers <br />
            (Legal basis: Justified interest on our part in direct marketing as
            long as this occurs in accordance with data protection and
            competition law). Provision of these Online Offers and fulfillment
            of a contract under our contractual terms including settlement.
            <br /> (Legal bases: Fulfillment of contract and/or our justified
            interest in efficient claims management regarding the sale of
            claims). To determine disruptions/disturbances and for security
            reasons. <br />
            (Legal bases: Fulfillment of our legal obligations within the scope
            of data security and justified interest in resulving
            disruptions/disturbances and in the security of our offers).
            Self-promotion and promotion by others, as well as market research
            and reach analysis within the scope statutorily permitted or based
            on consent.
            <br /> (Legal bases: Consent / justified interest on our part in
            direct marketing as long as this occurs in accordance with data
            protection and competition law). Dispatch of an email or SMS/MMS
            newsletter with the recipient’s consent. <br />
            (Legal basis: Consent). Safeguarding and vindication of our rights.
            <br /> (Legal basis: Justified interest on our part for the
            safeguarding and vindication of our rights).
          </p>
          <br />
          <p>
            <b>3.4. Registration</b>
          </p>
          <p>
            You can log in to our services using SingleKey ID.
            <br />
            <br />
            SingleKey ID was devised by Bosch.IO GmbH for the Bosch Group to
            provide users with a comprehensive login option on Bosch websites,
            shops, apps and services.
            <br />
            Bosch.IO GmbH, Ullsteinstrasse 128, 12109 Berlin, Germany, is
            responsible for providing SingleKey ID.
            <br />
            <br /> Bosch.IO GmbH processes your data for the purposes of
            "Registration and login with SingleKey ID" and "Overview and
            management of data and applications with SingleKey ID" in joint
            responsibility with us. For more information, see:
            <a href="https://singlekey-id.com/data-protection-notice/">
              https://singlekey-id.com/data-protection-notice/
            </a>
            . <br />
            <br />
            After a one-time registration, you can use SingleKey ID to log in.
            To do this, you will be forwarded to a login screen at Bosch.IO
            GmbH. After successful authentication, Bosch.IO GmbH provides us
            with the necessary personal data (e.g., e-mail address, telephone
            number, first name, last name, language, country). Your password
            will not be sent to us. <br />
            <br />
            You can terminate your SingleKey ID user agreement at any time on
            the SingleKey ID website by deleting your SingleKey ID:
            <a href="https://singlekey-id.com/myprofile/">
              https://singlekey-id.com/myprofile/
            </a>
            . <br />
            <br />
            Please note that by deleting your SingleKey ID you will lose access
            to all Bosch websites, shops, apps and services that you used to log
            in to with your SingleKey ID.
          </p>
          <br />

          <p>
            <b>3.4.1 iCPM, Joint Controllership, according to Art. 26 GDPR</b>
          </p>
          <p>
            Bosch "Integrated Customer Profile Management" (iCPM) connects
            different Bosch applications, with the aim that you as a business
            partner (user, customer) do not have to provide your data multiple
            times, thus offering you the best possible customer experience. In
            addition, iCPM offers the possibility to log in to Bosch
            applications via different login options.
            <br /> Within the framework of iCPM, <br />
            Robert Bosch Power Tools GmbH <br />
            Max-Lang-Strasse 40-46, D-70771 Leinfelden-Echterdingen hereinafter
            referred to as "Bosch PT and the parties listed in the "List of
            Parties" (hereinafter referred to as "Parties") work closely
            together. This also applies to the processing of your personal data.
            The Parties have jointly determined the order in which this data is
            to be processed in the individual process phases and are therefore
            to be regarded as joint controllers under data protection law
            pursuant to Art. 26 GDPR. <br />
            Here is a detailed overview of the joint and separate data
            processing activities and responsibilities of the parties:
            <br />
            <br />
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Data processing:
                  </th>
                  <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Responsibility:
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Identity Brokering with Bosch own as well as other, external{" "}
                    <br />
                    Identity Providers (Facebook, Apple, Google) for B2C and
                    external B2B users Bosch PT.
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Bosch PT
                  </td>
                </tr>
                <tr style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Identity brokering with BCD/Bosch ADFS for B2E (Bosch
                    administrators)
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Bosch PT
                  </td>
                </tr>
                <tr style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Capturing, storage and provision of user attributes for
                    connected applications
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    The Parties
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            What this means for you:
            <br /> - The parties shall provide you, as data subjects, with the
            information required by Articles 13 and 14 GDPR in a precise,
            transparent, understandable and easily accessible form in clear and
            simple language, free of charge. Each party shall provide the other
            party with all necessary information from its sphere of activity.
            <br /> - The parties shall inform each other without delay of the
            data subject rights asserted by you as a data subject. They shall
            provide each other with all information required to respond to your
            requests for information.
            <br /> - You may assert your data subject rights directly against
            either party at any time.
          </p>
          <p>
            <b>3.5. Log files</b>
          </p>
          <p>
            Every time you use the internet, your browser transmits certain
            information which we store in so-called log files. We save log files
            for a short time sulely to determine disturbances and for security
            reasons (e.g., to clarify attack attempts) and we delete them
            afterwards. Log files which need to be maintained for evidence
            purposes are excluded from deletion until the respective incident
            has been completely resulved and may, on a case-by-case basis, be
            passed on to investigating authorities. Log files are also used for
            analysis purposes (without the IP address or without complete IP
            address). See module web analysis therefore. <br />
            <br />
            In log files, the following information in particular is being
            saved: <br />
            <br />– IP address (internet protocol address) of the terminal
            device which is being used to access the Online Offers; <br />– Name
            of the files or information accessed; <br />– Amount of data
            transferred;
            <br />– Operating system and information on the internet browser
            used including add-ons installed (e.g., Flash Player);
            <br /> – http status code (e.g., “Request successful” or “File
            requested not found”).
          </p>

          <p>
            <b>3.6. Children</b>
          </p>
          <p>This Online Offer is not for children under 18 years of age.</p>
          <p>
            <b>3.7. Data transfer</b>
          </p>
          <p>
            <b>3.7.1. Data transfer to other controllers</b>
          </p>
          <p>
            Your personal data is principally forwarded to other controllers
            only when required for the fulfillment of a contract, in the case
            where we or the third party have a legitimate interest in the
            transfer, or when your consent has been given. Particulars on the
            legal bases can be found in the Section - Purposes of Processing and
            Legal Bases. Third parties may also be other companies of the Bosch
            group. When data is transferred to third parties based on a
            justified interest, this is explained in this data protection
            notice.
            <br />
            <br /> Additionally, data may be transferred to other controllers
            when we are obliged to do so due to statutory regulations or
            enforceable administrative or judicial orders.
          </p>

          <p>
            <b>3.7.2. Service providers (general)</b>
          </p>
          <p>
            We have commissioned external service providers with tasks such as
            data hosting. We have chosen these service providers carefully and
            review them regularly, especially regarding their diligent handling
            and protection of the data that they have saved. All service
            providers are obliged to maintain confidentiality and to abide by
            the statutory provisions. Service providers may also be other Bosch
            group companies.
          </p>
          <br />

          <p>
            <b>3.8. Duration of storage; retention periods</b>
          </p>
          <p>
            Principally, we store your data for as long as it is necessary to
            render our Online Offers and the services connected to them, or for
            as long as we have a justified interest in storing the data (e.g.,
            we might still have a justified interest in postal mail marketing
            upon fulfillment of a contract). In all other cases, we delete your
            personal data with the exception of data we must store to fulfill
            legal obligations (e.g., we are obliged due to retention periods
            under the tax and commercial codes to have documents such as
            contracts and invoices available for a certain period of time).
          </p>
          <br />
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <li>
            <h1>4. Web analysis</h1>
          </li>
          <p>
            We need statistical information about the usage of our Online Offers
            to design them to be more user-friendly, to perform range
            measurements and to perform market research.
            <br />
            <br /> For this purpose, we use the web analysis tools described in
            this section. <br />
            <br />
            The usage profiles created by these tools using analysis cookies or
            by evaluating log files do not contain personal data. The tools
            either do not use user IP addresses at all or shorten them
            immediately after gathering them.
            <br />
            <br /> The tool offerors process data only as processors, subject to
            our directives and not for their own purposes. In the following,
            please find information on each tool offeror and how you are able to
            object to the collection and processing of data through the tool.
          </p>
          <br />
          <p>
            <b>4.1. Google Analytics</b>
          </p>
          <p>
            Google Analytics is provided by Google Inc., 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA ("Google"). We use Google
            Analytics with the additional function offered by Google to
            anonymize IP addresses. While doing so, Google already shortens IPs
            within the EU in most cases but only does so for exceptional cases
            in the United States, and in both regions it only saves shortened
            IPs.
            <br />
            <br /> You may object to the collection or processing of your data
            by using the following link to download and install a browser
            plugin: http://tools.google.com/dlpage/gaoptout?hl=en
          </p>
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <li>
            <h1>5. Usage of our mobile applications</h1>
          </li>
          <p>
            In addition to our Online Offers, we offer mobile applications
            ("Apps"), which you can download to your mobile device. Beyond the
            data collected on websites, we collect additional personal data
            through our apps that specifically result from the usage of a mobile
            device. This occurs only when you expressly grant your consent.
          </p>

          <p>
            <b>5.1. Data processing by App Store operators</b>
          </p>
          <p>
            No data collection by us or outside our responsibility includes the
            transfer of data such as username, email address and individual
            device identifier to an app store (e.g., Google Play by Google, App
            Store by Apple, Galaxy Apps Store by Samsung) when downloading the
            respective application. We are unable to influence this data
            collection and further processing by the App Store as controller.
          </p>
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <li>
            <h1>6. Security</h1>
          </li>
          <p>
            Our employees and the companies providing services on our behalf,
            are obliged to confidentiality and to compliance with the applicable
            data protection laws.
            <br />
            <br /> We take all necessary technical and organizational measures
            to ensure an appropriate level of security and to protect your data
            that is administrated by us, especially from the risks of unintended
            or unlawful destruction, manipulation, loss, change or unauthorized
            disclosure or unauthorized access. Our security measures are,
            pursuant to technulogical progress, constantly being improved.
          </p>
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <li>
            <h1>7. User rights</h1>
          </li>
          <p>
            To enforce your rights, please use the details provided in the
            Contact section. In doing so, please ensure that an unambiguous
            identification of your person is possible.
            <br />
            <p>
              <b>7.1. Right to information and access:</b>
              <br />
              You have the right to obtain confirmation from us about whether or
              not your personal data is being processed, and, if this is the
              case, access to your personal data.
              <br />
            </p>
            <p>
              <b>7.2. Right to correction and deletion </b>
              <br />
              You have the right to obtain the rectification of inaccurate
              personal data concerning yourself without undue delay from us.
              Taking into account the purposes of the processing, you have the
              right to have incomplete personal data completed, including by
              means of providing a supplementary statement.
              <br />
              <br /> This does not apply to data which is necessary for billing
              or accounting purposes or which is subject to a statutory
              retention period. If access to such data is not required, however,
              its processing is restricted (see the following).
              <br />
            </p>
            <p>
              <b>7.3. Restriction of processing</b>
              <br />
              <br />
              You have the right to demand for – as far as statutory
              requirements are fulfilled – restriction of the processing of your
              data.
              <br />
            </p>
            <p>
              <b>7.4. Data portability</b>
              <br />
              <br />
              As far as statutory requirements are fulfilled you may request to
              receive data that you have provided to us in a structured,
              commonly used, and machine-readable format or – if technically
              feasible – that we transfer those data to a third party.
              <br />
            </p>
            <p>
              <b>7.5. Right of objection</b>
              <br />
              <br />
              You have the right to object to data processing by us at any time.
              We will no longer process the personal data unless we demonstrate
              compliance with legal requirements to provide provable reasons for
              the further processing, which are beyond your interests, rights
              and freedoms or for the establishment, exercise or defense of
              legal claims.
              <br />
            </p>
            <p>
              <b>7.5.1. Objection to direct marketing</b>
              <br />
              <br />
              Additionally, you may object to the processing of your personal
              data for direct marketing purposes at any time. Please take into
              account that, due to organizational reasons, there might be an
              overlap between your objection and the usage of your data within
              the scope of a campaign which is already running. Objection to
              data processing based on the legal basis of “justified interest”:
              In addition, you have the right to object to the processing of
              your personal data any time, insofar as this is based on the legal
              basis of justified interest. We will then terminate the processing
              of your data, unless we demonstrate compelling legitimate grounds
              according to legal requirements for the processing, which override
              your rights.
              <br />
            </p>
            <p>
              <b>
                7.5.2. Objection to data processing based on the legal basis of
                “legitimate interest”
              </b>
              <br />
              <br />
              In addition, you have the right to object to the processing of
              your personal data any time, insofar as this is based on the legal
              basis of legitimate interest. We will then terminate the
              processing of your data, unless we demonstrate compelling
              legitimate grounds according to legal requirements for the
              processing, which override your rights.
              <br />
            </p>
            <p>
              <b>7.6. Withdrawal of consent:</b>
              <br />
              <br />
              In case you consented to the processing of your data, you have the
              right to object this consent with immediate effect. The legality
              of data processing prior to your revocation remains unchanged.
              <br />
            </p>
            <p>
              <b>7.7. Right of complaint with supervisory authority</b>
              <br />
              <br />
              You have the right to lodge a complaint with a supervisory
              authority. You can appeal to the supervisory authority which is
              responsible for your place of residence or your state or to the
              supervisory authority responsible for us. <br />
              <br />
              Data Protection and Information Security Officer <br />
              <br />
              <p style={lineBreakStyle}>Email: DPO.southafrica@za.bosch.com</p>
              <p style={lineBreakStyle}>Phone: +27(12)381-3566</p>
            </p>
          </p>
          <br />
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <li>
            <h1>8. Changes to the Data Protection Notice</h1>
          </li>
          <p>
            We reserve the right to change our security and data protection
            measures if this is required due to technical development. In such
            cases, we will amend our data protection notice accordingly. Please
            therefore observe the current version of our data protection notice,
            as this is subject to change.
          </p>
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <li>
            <h1>9. Contact</h1>
          </li>
          <p>
            If you wish to contact us, please find us at the address stated in
            the "Controller" section.
          </p>
          <p>
            To assert your rights and to notify data protection incidents please
            the following link:
            <a href="https://www.bkms-system.net/bosch-dataprotection">
              https://www.bkms-system.net/bosch-dataprotection
            </a>
            <br />
            <p>
              For suggestions and complaints regarding the processing of your
              personal data we recommend that you contact our data protection
              officer:
            </p>
            <p style={lineBreakStyle}>
              Data Protection Officer <br />
              Information Security and Privacy (C/ISP)
            </p>
            <p style={lineBreakStyle}>Robert Bosch GmbH </p>
            <p style={lineBreakStyle}>Postfach 30 02 20 </p>
            <p style={lineBreakStyle}>70442 Stuttgart </p>
            <p style={lineBreakStyle}>GERMANY </p>
            or
            <p style={lineBreakStyle}>DPO@bosch.com</p>
          </p>
          <div
            style={{
              borderBottom: "2px solid #ddd", // Adjust the border style as needed
              margin: "10px 0", // Adjust the margin to control spacing
            }}
          ></div>
          <li>10. Effective date: 01.03.2024</li>
          {/* </ul> */}
        </section>
        <div
          style={{
            borderBottom: "2px solid #ddd", // Adjust the border style as needed
            margin: "10px 0", // Adjust the margin to control spacing
          }}
        ></div>
      </div>
      <h4>| © Robert Bosch East Africa Ltd. 2024, all rights reserved</h4>
      <img
        src={supergraphic}
        alt=""
        style={{ width: "100%", height: "15px" }}
      />
    </div>
  );
}

export default App;
